import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {SubLinkData} from "../../types/SubLink";
import {GameElementType} from "../game/Game";

interface SubmenuProps {
    links: SubLinkData[]
    onClick?:()=>void;
}


const Submenu: React.FC<SubmenuProps> = ({links,onClick}) => {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <>
            {screenSize.width >900 ?  <div className="navbar-submenu">
                <div className="navbar-submenu-inner">
                    {links.map(l => {
                        return <NavLink to={l.link} className="navbar-submenu-link">{l.name}</NavLink>
                    })}
                </div>
            </div> :
            <div className="navbar-submenu-mobile">
                {links.map(l => {
                    return <NavLink to={l.link} className="navbar-submenu-link" onClick={onClick}>{l.name}</NavLink>
                })}
            </div>
            }

        </>
    );
};

export default Submenu;