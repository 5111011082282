import React, {useEffect, useState} from 'react';
import "./schedule.css"
import {getDataFromSession, saveDataToSession, sessionStates} from "../../manager/sessionManager";
import {ArticleData} from "../../types/Article";
import he from "he";
import {GameData} from "../../types/Game.t";
import Game, {GameElementType} from "../../components/game/Game";
import {getClubImageByName} from "../../manager/dataFormatter";
import {Helmet} from "react-helmet";
import GamesSliderLine from "../../components/games_slider/GamesSliderLine";


interface CheckBoxProps {
    label: string,
    value: boolean,
    onChange: (name: string) => void;
}

const Checkbox: React.FC<CheckBoxProps> = ({label, value, onChange}) => {
    return (
        <label className="news-checkbox">
            <input type="checkbox" checked={value} onChange={() => onChange(label)}/>
            <span className="checkmark"></span>
            {label}
        </label>
    );
};

const Schedule = () => {
    const [games, setGames] = useState<GameData[]>()
    const [filteredGames, setFilteredGames] = useState<GameData[]>()
    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
        "Juli", "August", "September", "Oktober", "November", "Dezember"
    ];
    const [clubs, setClubs] = useState<any[]>()
    const [currentFilter, setCurrentFilter] = useState<string[]>([])
    const filterOptions = ["Herren 2.BL", "Damen 2.BL", "Herren GL"]

    useEffect(() => {
        let help = getDataFromSession("appointments");
        let help2 = getDataFromSession("clubs");

        if (help.sessionState === sessionStates.notFound || help2.sessionState === sessionStates.notFound) {
            innitData()
        } else {
            let help3 = JSON.parse(help2.data) as any[]
            setClubs((clubs) => help3)
            let help1 = JSON.parse(help.data) as GameData[]
            if (help1.length) {
                help1 = help1.filter(g => {
                    if (new Date(g.termin) > new Date()) {
                        return true
                    }
                })

                help1.sort((a, b) => new Date(a.termin).getTime() - new Date(b.termin).getTime())
            }
            setGames(help1)
            setFilteredGames(help1)
        }

    }, []);

    const innitData = () => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/posts?categories=11")
            .then(res => res.json())
            .then(data => {
                data.forEach((d: ArticleData) => {
                    const htmlTagRegex = /<\/?[^>]+(>|$)/g;
                    let cleanedText = he.decode(d.content.rendered.replace(htmlTagRegex, ''));
                    cleanedText = cleanedText.replace(/’|‘|‚|“|„|“|″|‘/g, '"');
                    saveDataToSession(cleanedText, d.title.rendered)

                    const sessionData = sessionStorage.getItem("appointments")
                    if (sessionData != null) {
                        let help1 = JSON.parse(sessionData) as GameData[]
                        if (help1.length) {
                            help1 = help1.filter(g => {
                                if (new Date(g.termin) > new Date()) {
                                    return true
                                }
                            })

                            help1.sort((a, b) => new Date(a.termin).getTime() - new Date(b.termin).getTime())
                        }
                        setGames(help1)
                        setFilteredGames(help1)
                    }
                })
            })
    }

    const handleCheckBoxClick = (filter: string) => {
        if (games) {
            let help = [...games];
            let helpFilter = [...currentFilter];

            if (filter !== "") {
                if (currentFilter.includes(filter)) {
                    const filterIndex = currentFilter.findIndex(value => value === filter);
                    helpFilter.splice(filterIndex, 1);
                    setCurrentFilter([...helpFilter]);
                } else {
                    helpFilter.push(filter);
                    setCurrentFilter([...helpFilter]);
                }
                if (helpFilter.length) {
                    help = help.filter(game => helpFilter.includes(game.filter));
                }
            }

            setFilteredGames([...help]);

        }

    }

    return (
        <div className="schedule-container">
            <Helmet>
                <title>VCH - Termine</title>
            </Helmet>
            <h1>Termine</h1>
            <hr/>
            <h2>Als nächstes:</h2>
            <GamesSliderLine></GamesSliderLine>
            <div className="schedule-filter">
                {filterOptions.map(f => {
                    return <Checkbox label={f} value={currentFilter.includes(f)}
                                     onChange={(f) => handleCheckBoxClick(f)}></Checkbox>
                })}
            </div>

            <div className="games-container">
                {filteredGames && filteredGames.length ? filteredGames?.map((g, index) => {
                    if (index > 2) {
                        g.gegner_pic = getClubImageByName(g.gegner, clubs ? clubs : [])
                        if (index === 3 || new Date(g.termin).getMonth() != new Date(filteredGames[index - 1].termin).getMonth()) {
                            return <>
                                <div className="games-month">
                                    {monthNames[new Date(g.termin).getMonth()]}
                                </div>
                                <hr/>
                                <Game game={g} startType={GameElementType.line}></Game>
                            </>
                        } else {
                            return <Game game={g} startType={GameElementType.line}></Game>
                        }
                    }
                }) : null}
            </div>
        </div>
    );
};

export default Schedule;