import React, {useState} from 'react';
import './App.css';
import Navbar from "./pages/navbar/Navbar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/home/Home";
import News from "./pages/news/News";
import Teams from "./pages/teams/Teams";
import Club from "./pages/club/Club";
import Schedule from "./pages/schedule/Schedule";
import About from "./pages/about/About";
import Impressum from "./pages/impressum/Impressum";
import Login from "./pages/login/Login";
import ArticleView from "./pages/article/ArticleView";
import {ArticleData} from "./types/Article";
import Cookies from "./pages/cookies/Cookies";
import Datenschutz from "./pages/datenschutz/Datenschutz";
import Kalender from "./pages/calender/Kalender";
import SubElem from "./pages/club/sub/SubElem";
import Trainingszeiten from "./pages/club/sub/Trainingszeiten";
import Halle from "./pages/club/sub/Halle";
import Geschichte from "./pages/club/sub/Geschichte";
import Statuten from "./pages/club/sub/Statuten";
import Kontakt from "./pages/club/sub/Kontakt";
import BLHerren from "./pages/teams/blherren/BLHerren";
import BLDamen from "./pages/teams/bldamen/BLDamen";

//TODO: Change homepage in package.json when deploying

function App() {

    return (
        <BrowserRouter>
            <Navbar>
                <Routes>
                    <Route path="/" element={<Home></Home>}></Route>
                    <Route path="/home" element={<Home></Home>}></Route>
                    <Route path="/news" element={<News></News>}></Route>
                    <Route path="/teams" element={<Teams></Teams>}></Route>
                    <Route path="/club" element={<Geschichte link={"630"}></Geschichte>}></Route>
                    <Route path="/schedule" element={<Schedule></Schedule>}></Route>
                    <Route path="/about" element={<About></About>}></Route>
                    <Route path="/impressum" element={<Impressum></Impressum>}></Route>
                    <Route path="/login" element={<Login></Login>}></Route>
                    <Route path="/cookies" element={<Cookies></Cookies>}></Route>
                    <Route path="/datenschutz" element={<Datenschutz></Datenschutz>}></Route>
                    <Route path="/articleView/:id" element={<ArticleView/>}></Route>
                    <Route path="/kalender" element={<Kalender/>}></Route>
                    <Route path='*' element={<Home/>}/>
                    <Route path="/trainingszeiten" element={<Trainingszeiten link={"634"}/>}></Route>
                    <Route path="/halle" element={<Halle link={"632"}/>}></Route>
                    <Route path="/geschichte" element={<Geschichte link={"630"}/>}></Route>
                    <Route path="/statuten" element={<Statuten link={"628"}/>}></Route>
                    <Route path="/kontakt" element={<Kontakt link={"626"}/>}></Route>
                    <Route path="/blherren" element={<BLHerren></BLHerren>}></Route>
                    <Route path="/bldamen" element={<BLDamen></BLDamen>}></Route>
                </Routes>
            </Navbar>
        </BrowserRouter>
    );
}

export default App;
