import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import Team from "../../../components/team/Team";



const BlHerren = () => {

    return (
        <div className="bl-herren teams-container">
            <h1>Bundesliga Herren</h1>
            <hr/>
            <Helmet>
                <title>VCH - BL-Herren</title>
            </Helmet>
            <Team teamName={"BL Herren"}></Team>
        </div>
    );
};

export default BlHerren;