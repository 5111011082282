import {IPlayer} from "../types/Player.t";
import {getDataFromSession} from "./sessionManager";
import {Simulate} from "react-dom/test-utils";
import play = Simulate.play;

export const getClubImageByName = (name: string, clubs: any[]): string => {
    for (const league in clubs) {
        const clubData = clubs[league];
        for (const clubName in clubData) {
            const clubInfo = clubData[clubName];
            if (clubInfo.team_table_name.includes(name)) {
                return clubInfo["logo"];
            }
        }
    }
    return ""
}

export const getPlayersOfTeam = (name:string):IPlayer[] => {
    let players = JSON.parse(getDataFromSession("player").data)

    return players.filter((player:IPlayer) => player.team === name);
}