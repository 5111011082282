import React from 'react';
import "./footer.css"
import {NavLink} from "react-router-dom";
import {BsFacebook, BsInstagram} from "react-icons/bs";
import {BiMailSend} from "react-icons/bi";
import logo from "../res/wimpel.webp"
const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-social">
                Follow us on Social Media:
                <div className="footer-social-icons">
                    <a href={"https://instagram.com/vch_hausmannstaetten"} target={"_blank"}><BsInstagram ></BsInstagram></a>
                    <a href={"http://www.facebook.com/VolleyballClubHausmannstaetten"} target={"_blank"}>  <BsFacebook></BsFacebook></a>
                    <a href={"mailto:news-subscribe@vch-online.at?subject=Newsletter%20abonnieren"} ><BiMailSend></BiMailSend></a>
                </div>
            </div>
            <div className="footer-logo">
                <img alt={"Logo"} src={logo}/>
            </div>
            <div className="footer-legal">
                <div className="footer-legal-container">
                    <NavLink to={"/impressum"}>Impressum</NavLink>
                    |
                    <NavLink to={"/datenschutz"}>Datenschutz</NavLink>
                    |
                    <NavLink to={"/cookies"}>Cookies</NavLink>
                </div>
            </div>
        </div>
    );
};

export default Footer;
