import React, {useEffect, useState} from 'react';
import {ArticleData} from "../../types/Article";
import "./impressum.css"
import {Helmet} from "react-helmet";

const Impressum = () => {
    const [impressumText,setImpressumText] = useState<ArticleData>()

    useEffect(() => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/posts/561")
            .then(res => res.json())
            .then(data => setImpressumText(data))
    }, []);
    return (
        <div className="impressum-container">
            <Helmet>
                <title>VCH - Impressum</title>
            </Helmet>
            <h1>{impressumText ? impressumText.title.rendered:""}</h1>
            <p dangerouslySetInnerHTML={{__html:impressumText ? impressumText.content.rendered:""}}></p>
        </div>
    );
};

export default Impressum;