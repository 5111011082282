import React, {useEffect, useRef} from 'react';
import {IPlayer} from "../../types/Player.t";
import "./player.css"
import {Simulate} from "react-dom/test-utils";
import play = Simulate.play;
import CSS from "csstype";

interface PlayerProps {
    player:IPlayer,
    isOpen:boolean,
    setIsOpen:() => void;
}

const Player:React.FC<PlayerProps> = ({player,setIsOpen,isOpen}) => {
    const myRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (myRef && myRef.current && isOpen) {
            myRef.current.scrollIntoView()
        }
    }, [isOpen]);

    const styleOpen:CSS.Properties = {
        height:'45rem',
        width:'45rem'
    }
    const styleOpenImg:CSS.Properties ={
        height:'30rem'
    }


    return (
        <div ref={myRef}  className={`player-container ${player.trainer ? "player-trainer": ""}`} onClick={()=>setIsOpen()} style={isOpen?styleOpen:undefined}>
            <div  className="player-pic" style={isOpen ? styleOpenImg:undefined}>
                <div className="player-pic-inner">
                    <img alt={player.firstname+player.lastname+"profile"} src={player.picture_url}/>
                </div>
            </div>
            <div className="player-overlay">
                <div className="player-overlay-inner">

                </div>
                <div className="player-name-line">{player.number} {player.firstname} {player.lastname} <hr/></div>
                <div className="player-position">
                    {player.position}
                </div>
            </div>
            <div className="player-open-container" style={isOpen?{maxHeight:'13rem'}:{maxHeight:'0rem'}}>
                <p><h3>Spitzname: </h3><h4>{player.nickname??""}</h4></p>
                <p><h3>Geburtstag: </h3><h4>{player.birthday?.toString()??""}</h4></p>
                <p><h3>Größe: </h3><h4>{player.height??""}</h4></p>
                <p><h3>Sprunghöhe: </h3><h4>{player.jump??""}</h4></p>
                <p><h3>Motto: </h3><h4>{player.motto??""}</h4></p>
                <p><h3>Lieblingsessen: </h3><h4>{player.food??""}</h4></p>
                <p><h3>Lieblingsgetränk: </h3><h4>{player.drink??""}</h4></p>
            </div>
        </div>
    );
};

export default Player;