import React from 'react';
import "./kalender.css"
const Kalender = () => {
    return (
        <div className="kalender-container" >
            <div className="kalender-topline">
                <h1>Termine</h1>
                <select className="kalender-dropdown-team">
                    <option value={"vch"}>VCH</option>
                </select>
            </div>
            <hr/>

        </div>
    );
};

export default Kalender;