import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {ArticleData} from "../../../types/Article";
import "./sub.css"
import {Helmet} from "react-helmet";
interface SubElemProps {
    link:string
}

const SubElem:React.FC<SubElemProps> = ({link}) => {
    const [article,setArticle] = useState<ArticleData>()

    useEffect(() => {

        fetch(`http://wordpress.vch-online.at/wp-json/wp/v2/posts/${link}`)
            .then(res => res.json())
            .then(data => setArticle(data))
    },[]);

    return (
        <div className="subelem-container">
            <Helmet>
                <title>VCH - Trainingszeiten</title>
            </Helmet>
            <h1 dangerouslySetInnerHTML={article?.title.rendered ? {__html: article?.title.rendered} : {__html: ""}}></h1>
            <div
                dangerouslySetInnerHTML={article?.content.rendered ? {__html: article?.content.rendered} : {__html: ""}}>

            </div>
        </div>
    );
};

export default SubElem;