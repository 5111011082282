import React, {useEffect, useState} from 'react';
import "./home.css"
import NewsHoverContainer from "../../components/news_hover/NewsHoverContainer";
import SponsorsLine from "../../components/sponsors_line/SponsorsLine";
import GamesSliderLine from "../../components/games_slider/GamesSliderLine";
import {CategoryData} from "../../types/Category";
import {ArticleData} from "../../types/Article";
import Tabelle from "../../components/table/tabelle";
import he from 'he'
import {Helmet} from "react-helmet"
import {
    getArticlesFromSession,
    getArticlesFromSessionByCategory,
    getCategoriesFromSession, saveArticlesToSession, saveCategoriesToSession, saveDataToSession,
    sessionStates
} from "../../manager/sessionManager";
import {NavLink} from "react-router-dom";

const Home = () => {
    const [categories, setCategories] = useState<CategoryData[]>()
    const [importantArticles, setImportantArticles] = useState<ArticleData[]>()
    const [articles, setArticles] = useState<ArticleData[]>()
    const [tableData, setTableData] = useState<any[]>([])

    useEffect(() => {
        let help1 = getCategoriesFromSession()

        if (help1.sessionState === sessionStates.correct){
            setCategories((categories) => [...help1.categories])
        }
        if (help1.sessionState === sessionStates.outdated){
            setCategories((categories) => [...help1.categories])
            getAllCategories()
        }
        if (help1.sessionState === sessionStates.notFound) {
            getAllCategories()
        }
        let help = getArticlesFromSession()

        if (help.sessionState === sessionStates.correct){
            setArticles((articles) => [...help.articles])
        }
        if (help.sessionState === sessionStates.outdated){
            setArticles((articles) => [...help.articles])
            getAllArticles()

        }
        if (help.sessionState === sessionStates.notFound) {
            getAllArticles()
        }
        const sessionData = sessionStorage.getItem("tables")
        if (sessionData != null) {
            setTableData(JSON.parse(JSON.parse(sessionData).data))
        }
        innitData()
    }, []);

    useEffect(() => {
        let lowerSearchTerm = "wichtig"
        let category = categories?.find(category => category.name.toLowerCase() === lowerSearchTerm || category.slug.toLowerCase() === lowerSearchTerm);
        let help = getArticlesFromSessionByCategory(category?.id ? category.id : 10)

        if (help.sessionState === sessionStates.correct){
            setImportantArticles((importantArticles) => [...help.articles])
        }
        if (help.sessionState === sessionStates.outdated){
            setImportantArticles((importantArticles) => [...help.articles])
            if (category) {
                getArticleByCategory(category?.id)
            }
        }
        if (help.sessionState === sessionStates.notFound) {
            if (category) {
                getArticleByCategory(category?.id)
            }
        }


    }, [categories]);


    const innitData = () => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/posts?categories=11&per_page=100")
            .then(res => res.json())
            .then(data => {
                data.forEach((d: ArticleData) => {
                    const htmlTagRegex = /<\/?[^>]+(>|$)/g;
                    let cleanedText = he.decode(d.content.rendered.replace(htmlTagRegex, ''));
                    cleanedText = cleanedText.replace(/’|‘|‚|“|„|“|″|‘/g, '"');
                    saveDataToSession(cleanedText, d.title.rendered)
                })
                const sessionData = sessionStorage.getItem("tables")
                if (sessionData != null) {
                    setTableData(JSON.parse(JSON.parse(sessionData).data))
                }

            })
    }

    const getAllArticles = () => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/posts?per_page=100&categories_exclude=13")
            .then(res => res.json())
            .then(data => {
                let sorted = data.sort((a: { date: string | number | Date; }, b: {
                    date: string | number | Date;
                }) => new Date(b.date).getTime() - new Date(a.date).getTime())
                setArticles(articles => ([...sorted]));
                saveArticlesToSession(sorted)
            })
    }
    const getAllCategories = () => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/categories?per_page=100&parent=12&hide_empty=true")
            .then(res => res.json())
            .then(data => {
                setCategories(data);
                saveCategoriesToSession(data);
            })

    }

    const getArticleByCategory = (catNr: number) => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/posts" + `?categories=${catNr}`)
            .then(res => res.json())
            .then(data => {
                data = data.filter((a: ArticleData) => !a.categories.includes(11))
                setImportantArticles(data.sort((a: { date: string | number | Date; }, b: {
                    date: string | number | Date;
                }) => new Date(b.date).getTime() - new Date(a.date).getTime()))
            })
    }


    return (
        <div>
            <Helmet>
                <title>VCH - Home</title>
            </Helmet>
            <NewsHoverContainer articles={importantArticles}></NewsHoverContainer>
            <div className="home-header">
                <NavLink to={"/schedule"}><h1>Nächste Spiele</h1></NavLink>
                <hr/>
            </div>
            <GamesSliderLine></GamesSliderLine>
            <div className="home-header">
                <h1>Tabellen</h1>
                <hr/>
            </div>
            <div className="tabellen">
                {tableData.map((d,index) => {
                    return <Tabelle key={index} bew_id={d.bew_id} title={d.name} link={d.link ? d.link : ""}></Tabelle>
                })}
            </div>
        </div>


    );
};

export default Home;