import React, {useEffect, useState} from 'react';
import {ArticleData} from "../../types/Article";
import "./articleview.css"
import {NavLink, useNavigate, useParams} from "react-router-dom";
const ArticleView = () => {
    const navigate = useNavigate();
    const {id}= useParams()
    const [notFound,setNotFound] = useState(false)
    const [article,setArticle] = useState<ArticleData>()
    const[imgUrl,setImgUrl] = useState<string>()

    useEffect(() =>{
       getMediaByID(article?.featured_media)
    },[article])

    useEffect(() => {
        let storedData = sessionStorage.getItem("currentArticle")
        if (storedData === null){
            getArticleByID()

        }
        else {
            let parsedArticle = JSON.parse(storedData).article
            if (id === parsedArticle?.id+''){
                setNotFound(false)
                setArticle(parsedArticle)
                let parsedImg = JSON.parse(storedData).imgUrl
                if (parsedImg != undefined) {
                    setImgUrl(parsedImg)
                }else {
                    getMediaByID(parsedArticle.featured_media)
                }

            }
            else {
                getArticleByID()
            }
        }
    }, []);

    useEffect(()=>{
        if (!imgUrl){
            getMediaByID(article?.featured_media)
        }
    },[article])

    const getMediaByID = (id: number) => {
        if (id && id != 0) {
            fetch("http://wordpress.vch-online.at/wp-json/wp/v2/media/" + id)
                .then(res => res.json())
                .then(data => {
                    if (data.guid.rendered) {
                        setImgUrl(data.guid.rendered)
                    }
                })
        }
    }

    const getArticleByID = () => {
        try {
            fetch(`http://wordpress.vch-online.at/wp-json/wp/v2/posts?include=${id}`)
                .then(res => res.json())
                .then(data => {setArticle(data[0])})
                .catch(() => setNotFound(true))
        }catch (error) {

        }

    }

    return (
        <div className="articleview-container">

            <h1 dangerouslySetInnerHTML={{__html: article?.title?.rendered ?? ""}}></h1>
            <div className="articleview-text" dangerouslySetInnerHTML={{__html: article?.content?.rendered ?? ""}}></div>
            {imgUrl ?   <img src={imgUrl} alt={"picture"} className={"articleview-img"}/> : null }
            <div onClick={() => navigate(-1)} className="articleview-back" >
                <u>Zurück</u>
            </div>

        </div>
    );
};

export default ArticleView;
