import React from 'react';
import "./news_hover_container.css"
import NewsHoverItem from "./NewsHoverItem";
import {ArticleData} from "../../types/Article";


interface HoverContainerProps {
    articles?:ArticleData[]
}
const HoverContainer:React.FC<HoverContainerProps> = ({articles}) => {
    return (
        <div className="news-hover-container">
            {articles?.map((a,index) => {

                if (index <= 2){
                    return <NewsHoverItem article={a} key={index}></NewsHoverItem>
                }
            })}
        </div>
    );
};

export default HoverContainer;
