import React, {useEffect, useState} from 'react';
import "./news.css"
import {ArticleData} from "../../types/Article";
import Article from "../../components/misc/Article";
import newsHoverContainer from "../../components/news_hover/NewsHoverContainer";
import {BsFilterRight} from "react-icons/bs";
import article from "../../components/misc/Article";
import {CategoryData} from "../../types/Category";
import {Helmet} from "react-helmet";
import {
    getArticlesFromSession,
    getCategoriesFromSession,
    saveArticlesToSession, saveCategoriesToSession,
    sessionStates
} from "../../manager/sessionManager";


interface CheckBoxProps {
    label: string,
    value: boolean,
    onChange: (name: string) => void;
}

const Checkbox: React.FC<CheckBoxProps> = ({label, value, onChange}) => {
    return (
        <label className="news-checkbox">
            <input type="checkbox" checked={value} onChange={() => onChange(label)}/>
            <span className="checkmark"></span>
            {label}
        </label>
    );
};

const News = () => {
    const [articles, setArticles] = useState<ArticleData[]>()
    const [years, setYears] = useState<number[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const [categories, setCategories] = useState<CategoryData[]>()
    const [categoriesFilter, setCategoriesFilter] = useState<string[]>([])
    const [yearsFilter,setYearsFilter]= useState<number[]>([])
    const [filteredArticles,setFilteredArticles] = useState<ArticleData[]>()

    useEffect(() => {
        const help1 = getCategoriesFromSession()

        if (help1.sessionState === sessionStates.correct){
            setCategories((categories) => [...help1.categories])
        }
        if (help1.sessionState === sessionStates.outdated){
            setCategories((categories) => [...help1.categories])
            getAllCategories()
        }
        if (help1.sessionState === sessionStates.notFound) {
            getAllCategories()
        }


        let help = getArticlesFromSession()
        if (help.sessionState === sessionStates.correct){
            setArticles((articles) => [...help.articles])
        }
        if (help.sessionState === sessionStates.outdated){
            setArticles((articles) => [...help.articles])
            getAllArticles()
        }
        if (help.sessionState === sessionStates.notFound) {
            getAllArticles()
        }
    }, [])

    useEffect(() => {
        let help: number[] = []
        articles?.map((a) => {

            if (!help.includes(new Date(a.date).getFullYear())) {
                help.push(new Date(a.date).getFullYear())
            }
        })
        setYears(help)
        onFilterClick()
    }, [articles])


    const getCategoryByID = (catID:number):string => {
        if (categories) {
            let help = categories?.find(obj => obj.id ===catID)
            if (help)
            return help!.name
        }
       return ""
    }
    const getAllArticles = () => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/posts?per_page=100&categories_exclude=13")
            .then(res => res.json())
            .then(data => {
                let sorted = data.sort((a: { date: string | number | Date; }, b: {
                    date: string | number | Date;
                }) => new Date(b.date).getTime() - new Date(a.date).getTime())
                setArticles(articles => ([...sorted]));
                saveArticlesToSession(sorted)
            })
    }


    const getAllCategories = () => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/categories?per_page=100&parent=12&hide_empty=true")
            .then(res => res.json())
            .then(data => {
                setCategories(data);
                saveCategoriesToSession(data);
            })

    }

    const handleOnCategoryChange = (name: string) => {
        let helpArray = categoriesFilter ?? []
        if (helpArray?.includes(name)) {
            const index = helpArray.indexOf(name, 0);
            if (index > -1) {
                helpArray.splice(index, 1);
            }
        } else {
            helpArray.push(name)
        }
        setCategoriesFilter((categoriesFilter) => [...helpArray])
    }

    const handleOnYearChange = (input: string) => {
        let year = parseInt(input)
        let helpArray = yearsFilter ?? []
        if (helpArray?.includes(year)) {
            const index = helpArray.indexOf(year, 0);
            if (index > -1) {
                helpArray.splice(index, 1);
            }
        } else {
            helpArray.push(year)
        }
        setYearsFilter((years) => [...helpArray])
    }

    const onFilterClick = () => {
        setIsOpen(!isOpen)
        let help: ArticleData[] = [...(articles || [])]; // Kopie erstellen

        if (yearsFilter?.length !== 0) {
            help = help.filter((a) => yearsFilter?.includes(new Date(a.date).getFullYear()));
        }

        if (categoriesFilter?.length !== 0) {
            help = help.filter((a) =>
                a.categories.some((cat) => categoriesFilter?.includes(getCategoryByID(cat)))
            );
        }


        setFilteredArticles(help);
    };


    return (
        <div className="news-container">
            <Helmet>
                <title>VCH - News</title>
            </Helmet>
            <div className="news-recent">
                <div className="news-header">
                    <h1>Aktuell</h1>

                </div>
                <hr/>
                {articles?.map((a, index) => {
                    if (index <= 3) {
                        if (a && a.excerpt && a.title && a.excerpt.rendered && a.title.rendered)
                        return <Article article={a}></Article>
                    }
                })}
            </div>
            <div className="news-archiv">
                <div className="news-header-archiv">
                    <div className="news-topline">
                        <h1>Archiv</h1>
                        <div className="news-filter">
                            <BsFilterRight onClick={() =>setIsOpen(!isOpen)}></BsFilterRight>
                        </div>
                    </div>
                    {isOpen ? <div className="news-filterPopUp">
                        <div className="news-filterCategory">
                            <h3>
                                Kategorie
                            </h3>
                            <div className="news-filter-categories">
                                {categories?.map((cat,index) => {
                                    return <Checkbox key={index} label={cat.name} value={!!categoriesFilter?.includes(cat.name)} onChange={(name) => handleOnCategoryChange(name)}></Checkbox>
                                })}
                            </div>

                        </div>
                        <div className="news-filterCategory">
                            <h3>
                                Jahr
                            </h3>
                            <div className="news-filter-categories">
                                {years?.map(y => {
                                    return <Checkbox label={y+''} value={!!yearsFilter?.includes(y)} onChange={(y) => handleOnYearChange(y)}></Checkbox>
                                })}
                            </div>
                        </div>
                        <div className="news-filterPopUp-filter">
                            <button onClick={()=>onFilterClick()}>Filter</button>
                        </div>
                    </div> : null}

                </div>
                <hr/>
                {filteredArticles?.map((a,index)=>{
                    if (a && a.excerpt && a.title && a.excerpt.rendered && a.title.rendered)
                    return <Article article={a}></Article>
                })}
            </div>
        </div>
    );
};

export default News;