import React, {useEffect, useState} from 'react';
import xml2js from 'xml2js'
import {TabelleData} from "../../types/Tabelle";
import "./tabelle.css"
import {getTableFromSession, saveTableToSession, sessionStates} from "../../manager/sessionManager";


interface TabelleProps {
    bew_id:string,
    title:string,
    link:string,
}

const Tabelle:React.FC<TabelleProps> = ({bew_id,title,link}) => {
    const [tableData, setTableData] = useState<TabelleData[]>([])
    const [rawData,setRawData] = useState<string>("")
    const parser = new xml2js.Parser();
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 620;
    const breakpoint2 = 1100;

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    useEffect(()=>{
        if (rawData!= "")
        transformRawData()
    },[rawData])



    useEffect(()=>{
        let help = getTableFromSession(bew_id)
        if (help.sessionState === sessionStates.notFound) {
            getRawData(bew_id)
        }
        if (help.sessionState === sessionStates.outdated) {

            setTableData((tableData) => [...help.table])
            getRawData(bew_id)
        }
        if (help.sessionState === sessionStates.correct) {

            setTableData((tableData) => [...help.table])
        }
    },[])

    const getRawData = (bew_id:string) => {
      fetch(`http://www.volleynet.at/volleynet/service/xml2.php?action=tabelle&[convert=xml]&bew_id=${bew_id}`)
          .then(res => res.text())
          .then(data => {setRawData(data)})
    }
    const transformRawData = () => {
        parser.parseString(rawData, (err, result) => {
            if (err) {
                console.error('Error parsing XML:', err);
            } else {
                const parsedObject = result.xml; // Access the parsed JavaScript object here
                let help = sessionStorage.getItem("clubs")
                if (help != null) {
                    let test =JSON.parse(help)
                    let transformData = JSON.parse(test.data)
                    parsedObject.tabelle.forEach((t:TabelleData) => {
                        if (transformData[title] && transformData[title][t.tea_name[0]])
                        {
                            t.tea_kurz = transformData[title][t.tea_name[0]]["team_table_name_short"] ?? t.tea_kurz
                            t.link = transformData[title][t.tea_name[0]]["team_link"] ?? undefined
                            t.tea_name = transformData[title][t.tea_name[0]]["team_table_name"] ?? t.tea_name
                        }
                    })
                }
                let h = parsedObject.tabelle.sort((a: { posdirekt: number; }, b: { posdirekt: number; })=>a.posdirekt-b.posdirekt)
                setTableData(h)
                saveTableToSession(h,bew_id)

            }
        });
    }



    return (
        <div className="table-container">
            {
                tableData && tableData.length && tableData.length != 0 ?
                    <>
                        <div className="table-title">
                            {link ? link != "" ? <a href={link} target={"_blank"}> <u>{title}</u> </a> : title :title}

                        </div>
                    <table>
                        <thead>
                        <tr>
                            <th style={{textAlign:"start"}}/>
                            <th style={{textAlign:"start"}}>Team</th>
                            <th>{width< breakpoint ? "P": "Punkte"}</th>
                            <th>{width< breakpoint ? "G": "Gespielt"}</th>
                            <th>{width< breakpoint ? "+": "Gewonnen"}</th>
                            <th>{width< breakpoint ? "-": "Verloren"}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData?.map((t,index) => {
                            if(t.tea_name.includes("VC Hausmannstätten") || t.tea_name.includes("VC Hausmannstätten 2")) {
                                return <tr key={index} style={{backgroundColor:"#4E9C5D"}}>
                                    <td style={{textAlign:"start"}}>{index+1}</td>
                                    <td style={{textAlign:"start"}}>{t.link ? <a href={t.link} target={"_blank"}>{width< breakpoint2 ?t.tea_kurz:t.tea_name}</a> : <>{width< breakpoint2 ? t.tea_kurz:t.tea_name}</>}</td>
                                    <td>{t.punkte}</td>
                                    <td>{t.gespielt}</td>
                                    <td>{t.gewonnen}</td>
                                    <td>{t.verloren}</td>
                                </tr>
                            }else
                            return <tr>
                                <td style={{textAlign:"start"}}>{index+1}</td>
                                <td style={{textAlign:"start"}}>{t.link ? <a href={t.link} target={"_blank"}>{width< breakpoint2 ?t.tea_kurz:t.tea_name}</a> : <>{width< breakpoint2 ? t.tea_kurz:t.tea_name}</>}</td>
                                <td>{t.punkte}</td>
                                <td>{t.gespielt}</td>
                                <td>{t.gewonnen}</td>
                                <td>{t.verloren}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </>:null
            }

        </div>
    );
};

export default Tabelle;
