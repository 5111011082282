import {ArticleData} from "../types/Article";
import {CategoryData} from "../types/Category";
import {TabelleData} from "../types/Tabelle";

export enum sessionStates {
    notFound = 0,
    outdated = 1,
    correct = 2
}

export const saveArticlesToSession = (articles: ArticleData[]) => {
    if (articles && articles.length && articles[0].categories) {
        const sessionData = {
            "articles": articles,
            "lastUpdate": new Date()
        }

        sessionStorage.setItem("articles", JSON.stringify(sessionData))
    }
}

export const saveDataToSession = (data: string, name: string) => {
    if (data != "" && name != "articles" && name != "table" && name != "categories") {
        const sessionData = {
            "data": data,
            "lastUpdate": new Date()
        }

        sessionStorage.setItem(name, JSON.stringify(sessionData))
    }
}

export const saveTableToSession = (table:TabelleData[],id:string) =>{
    if (table && table.length) {
        const sessionData = {
            "table": table,
            "lastUpdate": new Date()
        }

        sessionStorage.setItem(`table_${id}`, JSON.stringify(sessionData))
    }
}

export const saveCategoriesToSession = (categories: CategoryData[]) => {
    if (categories && categories.length) {
        const sessionData = {
            "categories": categories,
            "lastUpdate": new Date()
        }

        sessionStorage.setItem("categories", JSON.stringify(sessionData))
    }
}

export const getCategoriesFromSession = (): any => {
    const now = new Date()
    const compareDate = new Date(now.getTime() - 60000)
    let storedData = sessionStorage.getItem("categories")
    if (storedData === null) {
        return {sessionState: sessionStates.notFound}
    } else {
        let storedDate = new Date(JSON.parse(storedData).lastUpdate)
        let help = JSON.parse(storedData).categories
        if (!(help && help.length)) {
            return {sessionState: sessionStates.notFound}
        }

        if (storedDate >= compareDate) {

            return {
                sessionState: sessionStates.correct,
                categories:help
            }
        }else{

            return {
                sessionState: sessionStates.outdated,
                categories:help
            }
        }
    }
}
export const getTableFromSession = (id:string) =>{
    const now = new Date()
    const compareDate = new Date(now.getTime() - 60000)
    let storedData = sessionStorage.getItem(`table_${id}`)
    if (storedData===null){
        return {sessionState: sessionStates.notFound}
    }else {
        let storedDate = new Date(JSON.parse(storedData).lastUpdate)
        let help = JSON.parse(storedData).table
        if (storedDate >= compareDate) {
            return {
                sessionState: sessionStates.correct,
                table:help
            }
        }else{
            return {
                sessionState: sessionStates.outdated,
                table:help
            }
        }
    }
}

export const getDataFromSession = (name:string):any => {
    const now = new Date()
    const compareDate = new Date(now.getTime() - 60000)
    let storedData = sessionStorage.getItem(name)
    if (storedData === null) {
        return {sessionState: sessionStates.notFound}
    } else {
        let storedDate = new Date(JSON.parse(storedData).lastUpdate)
        let help = JSON.parse(storedData).data
        if (storedDate >= compareDate) {
            return {
                sessionState: sessionStates.correct,
                data: help
            }
        } else {
            return {
                sessionState: sessionStates.outdated,
                data: help
            }
        }
    }
}

export const getArticlesFromSessionByCategory = (id:number): any => {

    const now = new Date()
    const compareDate = new Date(now.getTime() - 60000)
    let storedData = sessionStorage.getItem("articles")

    if (storedData === null) {
        return {sessionState: sessionStates.notFound}
    } else {
        let storedDate = new Date(JSON.parse(storedData).lastUpdate)
        let help = JSON.parse(storedData).articles
        if (!(help && help.length)) {
            return {sessionState: sessionStates.notFound}
        }
        help = help.filter((a:ArticleData) => a.categories.includes(id))
        if (storedDate >= compareDate) {
            return {
                sessionState: sessionStates.correct,
                articles:help
            }
        }else{
            return {
                sessionState: sessionStates.outdated,
                articles:help
            }
        }
    }
}

export const getArticlesFromSession = (): any => {

    const now = new Date()
    const compareDate = new Date(now.getTime() - 60000)
    let storedData = sessionStorage.getItem("articles")
    if (storedData === null) {

        return {sessionState: sessionStates.notFound}
    } else {
        let storedDate = new Date(JSON.parse(storedData).lastUpdate)
        let help = JSON.parse(storedData).articles
        if (!(help && help.length)) {
            return {sessionState: sessionStates.notFound}
        }
        if (storedDate >= compareDate) {

            return {
                sessionState: sessionStates.correct,
                articles:help
            }
        }else{

            return {
                sessionState: sessionStates.outdated,
                articles:help
            }
        }
    }
}

