import React from 'react';
import {Helmet} from "react-helmet";
import Team from "../../../components/team/Team";

const BlDamen = () => {
    return (
        <div className="bl-damen teams-container">
            <h1>Bundesliga Damen</h1>
            <hr/>
            <Helmet>
                <title>VCH - BL-Damen</title>
            </Helmet>
            <Team teamName={"BL Damen"}></Team>
        </div>
    );
};

export default BlDamen;