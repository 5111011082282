import React, {FormEvent, useRef, useState} from 'react';
import "./login.css"
import {BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill} from "react-icons/bs";
import {useNavigate} from 'react-router-dom'
import logo from "../res/wimpel.webp"

const Login = () => {

    const [errorMessageText, setErrorMessageText] = useState("")
    const errorMessage = useRef(null)
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessageText("")
        if (e.currentTarget.inputUsername.value === "" && e.currentTarget.inputPassword.value === "") {
            setErrorMessageText("Username and Password are required")
        } else if (e.currentTarget.inputUsername.value === "") {
            setErrorMessageText("Username is required")
        } else if (e.currentTarget.inputPassword.value === "") {
            setErrorMessageText("Password is required")
        } else {

        }
    }

    return (
        <div className="login-page-container">
            <div className="login-page-back">
                <BsFillArrowLeftCircleFill onClick={goBack}></BsFillArrowLeftCircleFill>
            </div>

            <div className="login-page-menu-container">
                <div className="login-page-logo">
                    <img src={logo} alt="logo"/>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="login-page-item">
                        <label htmlFor="inputUsername">Username</label>
                        <input type="text" id="inputUsername" name="inputUsername" placeholder="name"
                               defaultValue={""}/>
                    </div>
                    <div className="login-page-item">
                        <label htmlFor="inputPassword">Password</label>
                        <input type="password" id="inputPassword" name="inputPassword" placeholder="password"
                               defaultValue={""}/>
                    </div>
                    <div className="login-page-submit">
                        <button type="submit" className="login-page-submit-button">
                            <BsFillArrowRightCircleFill></BsFillArrowRightCircleFill>
                        </button>
                    </div>

                    <div className="login-page-error" ref={errorMessage}>
                        {errorMessageText}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
