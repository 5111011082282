import React, {useEffect, useState} from 'react';
import {ArticleData} from "../../types/Article";
import {NavLink} from "react-router-dom";
interface ArticleProps {
    article:ArticleData
}
const Article:React.FC<ArticleProps>  = ({article}) => {
    const [date,setDate] = useState(new Date(article.date))

    return (
        <div className="news-article-container">
            <div className="news-article-head">
                <div className="news-article-title" dangerouslySetInnerHTML={{__html: article.title.rendered}} />
                <div className="news-article-date">{date.getDate()}.{date.getMonth()+1}.{date.getUTCFullYear()} </div>
            </div>
            <div className="news-article-text" dangerouslySetInnerHTML={{__html: article.excerpt.rendered}} />
            <NavLink to={`/articleView/${article.id}`} className="news-article-btcontinue">Weiterlesen</NavLink>
        </div>

    );
};

export default Article;
