import React, {useEffect, useRef, useState} from 'react';
import {IPlayer} from "../../types/Player.t";
import Teams from "../../pages/teams/Teams";
import {getPlayersOfTeam} from "../../manager/dataFormatter";
import Player from "./Player";

interface TeamProps {
    teamName:string
}
const Team:React.FC<TeamProps> = ({teamName}) => {
    const [openPlayer,setOpenPlayer] = useState<IPlayer>()


    const [players,setPlayers] = useState<IPlayer[]>()
    useEffect(() => {
        setPlayers(getPlayersOfTeam(teamName))
    }, []);

    return (
       <div className="team-container">
           {players?.map(p => {
               return <Player isOpen={openPlayer === p} player={p}  setIsOpen={()=>setOpenPlayer(openPlayer===p?undefined:p)}></Player>
           })}
       </div>
    );
};

export default Team;