import React, {useEffect, useState} from 'react';
import "./game.css"
import {GameData} from "../../types/Game.t";
import CSS from 'csstype';
import {IoLocationSharp} from "react-icons/io5";

export enum GameElementType {
    square,
    line
}

interface GameProps {
    game: GameData
    startType: GameElementType
}

const Game: React.FC<GameProps> = ({game, startType}) => {
    const [type,setType] = useState<GameElementType>(startType)
    const styleFB: CSS.Properties = {
        // border: '4px solid #fabef1'
    }
    const styleMB: CSS.Properties = {
        // border: '4px solid #71b3f5'
    }
    const styleMG: CSS.Properties = {
        // border: '4px solid #1e3042'
    }
    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
        "Juli", "August", "September", "Oktober", "November", "Dezember"
    ];
    const dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension(){
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        if (screenSize.width < 600) {
            setType(GameElementType.square)
        }
        if (screenSize.width > 600 && startType !== GameElementType.square) {
            setType(GameElementType.line)
        }

        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <>
            {type === GameElementType.line ?
                <div className="game-line-container"
                     style={game.bewerb.includes("Damen") && game.bewerb.includes("Bundesliga") ? styleFB : game.bewerb.includes("Herren") && game.bewerb.includes("Bundesliga") || game.bewerb.includes("Cup") ? styleMB : styleMG}>
                    <div className="game-line-date">
                        <div className="game-square-dayname">
                            {dayNames[new Date(game.termin).getDay()]}
                        </div>
                        <div className="helper-container-gameline">
                            <div className="game-line-day">
                                {new Date(game.termin).getDate()}.
                            </div>


                            <div className="game-line-month">
                                {monthNames[new Date(game.termin).getMonth()]}
                            </div>
                        </div>
                    </div>
                    <div className="game-line-time">
                        {new Date(game.termin).getHours()}:{('0' + new Date(game.termin).getMinutes()).slice(-2)} Uhr
                    </div>
                    <div className="game-line-teams">
                        <div className="game-line-heim game-line-team">
                            {game.heim}
                            <img
                                src={game.heim.includes("Hausmannstätten") ? "http://wordpress.vch-online.at/wp-content/uploads/2023/09/VC-Hausmannstaetten_gross.png" : game.gegner_pic ? game.gegner_pic : "https://clipart-library.com/image_gallery/348693.jpg"}/>
                        </div>
                        <p>vs.</p>
                        <div className="game-line-auswaerts game-line-team">
                            <img
                                src={game.auswaerts.includes("Hausmannstätten") ? "http://wordpress.vch-online.at/wp-content/uploads/2023/09/VC-Hausmannstaetten_gross.png" : game.gegner_pic ? game.gegner_pic : "https://clipart-library.com/image_gallery/348693.jpg"}/>
                            {game.auswaerts}
                        </div>
                    </div>
                    <div className="game-line-bewerb">
                        {game.bewerb.replace(/ Süd Grunddurchgang| Grunddurchgang/, '')}
                    </div>
                    <div className="game-line-location">
                        <a href={`https://www.google.com/maps/search/?api=1&query=${game.ort}`}
                           target={"_blank"}> {game.ort} <IoLocationSharp/></a>
                    </div>
                </div>
                :
                <div className="game-square-container">
                    <div className="game-square-date">
                        <div className="game-square-day">
                            {new Date(game.termin).getDate()}
                        </div>
                        <div className="game-square-date-helper">
                            <div className="game-square-dayname">
                                {dayNames[new Date(game.termin).getDay()]}
                            </div>
                            <div className="game-square-month">
                                {monthNames[new Date(game.termin).getMonth()]}
                            </div>
                        </div>
                        <div className="game-square-bewerb">
                            {game.bewerb_kurz}
                        </div>
                    </div>
                    <div className="game-square-teams">
                        <div className="game-square-heim game-square-team">
                            <img
                                src={game.heim.includes("Hausmannstätten") ? "http://wordpress.vch-online.at/wp-content/uploads/2023/09/VC-Hausmannstaetten_gross.png" : game.gegner_pic ? game.gegner_pic : "https://clipart-library.com/image_gallery/348693.jpg"}/>
                        </div>
                        <div className="game-square-time">
                            {new Date(game.termin).getHours()}:{('0' + new Date(game.termin).getMinutes()).slice(-2)}
                        </div>
                        <div className="game-square-auswaerts game-square-team">
                            <img
                                src={game.auswaerts.includes("Hausmannstätten") ? "http://wordpress.vch-online.at/wp-content/uploads/2023/09/VC-Hausmannstaetten_gross.png" : game.gegner_pic ? game.gegner_pic : "https://clipart-library.com/image_gallery/348693.jpg"}/>
                        </div>
                    </div>
                    <div className="game-square-teams-text">
                        <h4>{game.heim}</h4>
                        <h5>vs</h5>
                        <h4>{game.auswaerts}</h4>
                    </div>
                    <hr/>
                    <div className="game-square-location">
                        <a href={`https://www.google.com/maps/search/?api=1&query=${game.ort}`}
                           target={"_blank"}> {game.ort} <IoLocationSharp/></a>

                    </div>
                </div>
            }
        </>

    );
};

export default Game;