import React, {useEffect, useState} from 'react';
import {ArticleData} from "../../types/Article";
import "../impressum/impressum.css"
import {Helmet} from "react-helmet";

const Datenschutz = () => {
    const [dataText,setDataText] = useState<ArticleData>()

    useEffect(() => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/posts/565")
            .then(res => res.json())
            .then(data => setDataText(data))
    }, []);
    return (
        <div className="impressum-container">
            <Helmet>
                <title>VCH - Datenschutz</title>
            </Helmet>
            <h1>{dataText ? dataText.title.rendered:""}</h1>
            <p dangerouslySetInnerHTML={{__html:dataText ? dataText.content.rendered:""}}></p>
        </div>
    );
};

export default Datenschutz;
