import React, {ReactNode, useState} from 'react';
import "./navbar.css"
import {NavLink} from 'react-router-dom';

import {Squash as Hamburger} from 'hamburger-react'
import logo from "../res/wimpel.webp"
import {AiFillHome, AiOutlineSchedule, AiOutlineTeam} from "react-icons/ai"
import {BiNews} from "react-icons/bi";
import {RiTeamFill} from "react-icons/ri";
import {ImInfo, ImParagraphRight} from "react-icons/im";
import {MdOutlineAccountCircle} from "react-icons/md"
import Footer from "../../components/footer/Footer";
import {GrSchedule} from "react-icons/gr";
import Submenu from "../../components/submenu/Submenu";
import gamesSliderElement from "../../components/games_slider/GamesSliderElement";

interface Props {
    children?: ReactNode
    // any props that come into the component
}

const Navbar = ({children, ...props}: Props) => {


    const [toggleMenu, setToggleMenu] = useState(false)
    const menuItem = [
        {
            path: "/",
            name: "Home",
            icon: <AiFillHome></AiFillHome>
        },
        {
            path: "/news",
            name: "News",
            icon: <BiNews></BiNews>
        },
        {
            path: "/schedule",
            name: "Termine",
            icon: <GrSchedule></GrSchedule>
        },
        {
            disabled: true,
            path: "/teams",
            name: "Teams",
            icon: <AiOutlineTeam></AiOutlineTeam>,
            submenu: <Submenu links={[{name:"Bundesliga Herren",link:"/blherren"},{name:"Bundesliga Damen",link:"/bldamen"}]} onClick={() => setToggleMenu(false)}></Submenu>
        },
        {
            disabled: true,
            path: "/club",
            name: "Verein",
            icon: <RiTeamFill></RiTeamFill>,
            submenu: <Submenu links={[{name: "Trainingszeiten", link: "/trainingszeiten"}, {
                name: "Halle",
                link: "/halle"
            }, {name: "Geschichte", link: "/geschichte"}, {name: "Statuten", link: "/statuten"}, {
                name: "Kontakt",
                link: "/kontakt"
            }]} onClick={() => setToggleMenu(false)}></Submenu>
        },

    ]

    return (

        <>
            <div className="navbar-container">
                <NavLink to={"/"}>
                    <div className="navbar-logo">
                        <img src={logo} alt="logo"/>
                    </div>
                </NavLink>
                {
                    menuItem.map((item, index) => (
                        <div className="navbar-link-element">
                            {item.disabled ? <div className="navbar-link">
                                <div className="navbar-link-logo">{item.icon}</div>
                                <div className="navbar-link-text">{item.name} <i className="arrow down"></i></div>
                            </div> : <NavLink to={item.path} key={index} className="navbar-link">
                                <div className="navbar-link-logo">{item.icon}</div>
                                <div className="navbar-link-text">{item.name} <i className="arrow down"></i></div>
                            </NavLink>}

                            {item.submenu ? item.submenu : null}
                        </div>
                    ))
                }


                <div className="navbar-hamburger">
                    <Hamburger size={20} toggled={toggleMenu} toggle={setToggleMenu}></Hamburger>
                </div>
                {
                    toggleMenu &&
                    (
                        <div className="navbar-menu-container scale-up-center">
                            {
                                menuItem.map((item, index) => (
                                    <>
                                        {item.disabled ? <div className="navbar-menu-link">
                                            <div className="navbar-menu-link-text">{item.name}</div>
                                        </div> : <NavLink to={item.path} key={index} className="navbar-menu-link"
                                                          onClick={() => setToggleMenu(false)}>
                                            <div className="navbar-menu-link-text">{item.name}</div>
                                        </NavLink>}

                                        {item.submenu ? <hr/> : null}
                                        {item.submenu ? item.submenu : null}
                                    </>
                                ))
                            }
                        </div>
                    )}

            </div>


            <div className="page-content">
                <main>{children}</main>
            </div>
            <Footer></Footer>
        </>
    );
};

export default Navbar;