import React, {useEffect, useState} from 'react';
import "./gamessliderline.css"
import {GameData} from "../../types/Game.t";
import {getDataFromSession, saveDataToSession, sessionStates} from "../../manager/sessionManager";
import {ArticleData} from "../../types/Article";
import he from "he";
import Game, {GameElementType} from "../game/Game";
import {getClubImageByName} from "../../manager/dataFormatter";


const GamesSliderLine = () => {
    const [games, setGames] = useState<GameData[]>()
    const [clubs, setClubs] = useState<any[]>()

    useEffect(() => {
        let help = getDataFromSession("appointments");
        let help2 = getDataFromSession("clubs");

        if (help.sessionState === sessionStates.notFound || help2.sessionState === sessionStates.notFound) {
            innitData()
        } else {
            let help3 = JSON.parse(help2.data) as any[]
            setClubs((clubs) => help3)
            let help1 = JSON.parse(help.data) as GameData[]
            if (help1.length) {
                help1 = help1.filter(g => {
                    if (new Date(g.termin) > new Date()) {
                        return true
                    }
                })

                help1.sort((a, b) => new Date(a.termin).getTime() - new Date(b.termin).getTime())
            }
            setGames(help1)
        }

    }, []);


    const innitData = () => {
        fetch("http://wordpress.vch-online.at/wp-json/wp/v2/posts?categories=11")
            .then(res => res.json())
            .then(data => {
                data.forEach((d: ArticleData) => {
                    const htmlTagRegex = /<\/?[^>]+(>|$)/g;
                    let cleanedText = he.decode(d.content.rendered.replace(htmlTagRegex, ''));
                    cleanedText = cleanedText.replace(/’|‘|‚|“|„|“|″|‘/g, '"');
                    saveDataToSession(cleanedText, d.title.rendered)
                    let curClubs: any[] = []
                    const sessionDataClubs = sessionStorage.getItem("clubs")
                    if (sessionDataClubs != null) {
                        let help2 = JSON.parse(JSON.parse(sessionDataClubs).data)

                        setClubs(help2)
                        curClubs = help2



                    }

                    const sessionData = sessionStorage.getItem("appointments")
                    if (sessionData != null) {
                        let help1 = JSON.parse(JSON.parse(sessionData).data) as GameData[]
                        if (help1.length) {
                            help1 = help1.filter(g => {
                                if (new Date(g.termin) > new Date()) {
                                    return true
                                }
                            })

                            help1.map(t => {
                                t.gegner_pic = getClubImageByName(t.gegner, curClubs ? curClubs : [])

                            })
                            help1.sort((a, b) => new Date(a.termin).getTime() - new Date(b.termin).getTime())
                        }

                        setGames(help1)
                    }
                })
            })
    }

    return (
        <div className="games-slider-line">
            {games && games.length ? games.map((value, index) => {
                value.gegner_pic = getClubImageByName(value.gegner, clubs ? clubs : [])
                if (index < 3) {

                    return <Game game={value} startType={GameElementType.square}></Game>
                }
            }) : null}
        </div>
    );
};

export default GamesSliderLine;
