import React from 'react';
import "./teams.css"
import {Helmet} from "react-helmet";
const Teams = () => {
    return (
        <div>
            <Helmet>
                <title>VCH - Teams</title>
            </Helmet>
            Teams
        </div>
    );
};

export default Teams;