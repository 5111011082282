import React, {useEffect, useState} from 'react';
import ironman from "../res/IronMan.jpg"
import {ArticleData} from "../../types/Article";
import herren from "../res/standard_pics/4.jpg"
import damen from "../res/standard_pics/7.jpg"
import beach from "../res/standard_pics/9.jpg"
import jugend from "../res/standard_pics/8.jpg"
import placeholder from "../res/standard_pics/placeholder.png"
import {NavLink} from "react-router-dom";

interface HoverItemProps {
    article: ArticleData
}

const HoverItem: React.FC<HoverItemProps> = ({article}) => {
    const pathname = window.location.href
    const [imgUrl, setImgUrl] = useState<string>()

    useEffect(() => {
        getMediaByID(article.featured_media)
    }, []);

    const getMediaByID = (id: number) => {
        if (id != 0) {
            fetch("http://wordpress.vch-online.at/wp-json/wp/v2/media/" + id)
                .then(res => res.json())
                .then(data => {
                    if (data.guid.rendered) {
                        setImgUrl(data.guid.rendered)
                    } else {
                        setImgUrl(article.categories[1] === 4 || 5 ? herren : article.categories[1] === 6 || 7 ? damen : article.categories[1] === 8 ? beach : jugend)
                    }
                })
        } else {
            setImgUrl(article.categories[1] === 4 || 5 ? herren : article.categories[1] === 6 || 7 ? damen : article.categories[1] === 8 ? beach : jugend)
        }
    }


    return (

        <NavLink to={`/articleView/${article.id}`} className="news-hover-item">
            <img src={imgUrl} alt={article.title.rendered}/>
            <div className="news-hover-item-text">
                <h1 dangerouslySetInnerHTML={{__html: article.title.rendered}}></h1>
                <h3 dangerouslySetInnerHTML={{__html: article.excerpt.rendered}}></h3>
            </div>
        </NavLink>
    );
};

export default HoverItem;
